<template>
  <v-card>
    <v-card-title>
      <h3>Content Output Pools</h3>
      <v-spacer />
      <v-col v-if="isSuperAdmin" >
        <ApolloQuery :query="require('@/graphql/client/clientsDropdown.gql')">
          <template v-slot="{ result: { loading, error, data } }">
            <v-autocomplete
              v-if="data"
              :items="data.allClients"
              :loading="loading"
              item-text="name"
              item-value="id"
              v-model="client_id"
              label="Client"
              clearable
            />
            <v-autocomplete v-else label="Loading Clients" />
          </template>
        </ApolloQuery>
      </v-col>
      <v-col>
        <v-menu
          v-model="showStartDatePicker"
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="theStartPublishDateText"
              label="Publish start date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              class="datepicker"
              clearable
              @click:clear="clearOutputStartDate"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="outputFilterStartDate"
            @input="updateStartDate"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
          v-model="showEndDatePicker"
          :close-on-content-click="true"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="theEndPublishDateText"
              label="Publish end date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              class="datepicker"
              clearable
              @click:clear="clearOutputEndDate"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="outputFilterEndDate"
            @input="updateEndDate"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col style="margin-bottom: 22px">
        <v-text-field
          v-model="keywords"
          append-icon="mdi-magnify"
          label="Keyword..."
          clearable
          single-line
          hide-details
        />
      </v-col>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            color="primary"
            @click="newOutputPoolForm()"
            fab
            dark
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>New Output Pool</span>
      </v-tooltip>
    </v-card-title>
    <v-alert type="error" v-if="inValidOutputFilterDates">
          The Content Output Pools publish end date can not be earlier than the start date.
    </v-alert>
    <v-card-text>
      <output-pool-grid
        :keywords.sync="keywords"
        :client_id.sync="client_id"
        :project_id.sync="project_id"
        :reload.sync="reloadGrid"
        :outputFilterEndDate.sync="outputFilterEndDate"
        :outputFilterStartDate.sync="outputFilterStartDate"
        @edit-pool="editOutputPool"
        @open-version-dialog="openVersionDialog"
      />
    </v-card-text>
    <v-dialog
      v-model="versionDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <version-dialog
        :pool.sync="currentPool"
        @close-version-dialog="closeVersionDialog()"
        @version-updated="reloadTheGrid"
      />
    </v-dialog>
    <v-dialog v-model="outputPoolFormDialog" persistent max-width="600">
      <output-pool-form
        :pool.sync="formOutputPool"
        @pool-form-saved="
          closeOutputPoolForm();
          reloadTheGrid();
        "
        @pool-form-closed="closeOutputPoolForm()"
      />
    </v-dialog>
  </v-card>
</template>
<script>
import OutputPoolGrid from "@/views/output-pool/OutputPoolGrid";
import OutputPoolForm from "@/views/output-pool/OutputPoolForm";
import VersionDialog from "@/views/output-pool/VersionDialog";
import moment from "moment";

export default {
  name: "home",
  components: {
    "output-pool-grid": OutputPoolGrid,
    "output-pool-form": OutputPoolForm,
    "version-dialog": VersionDialog
  },
  props: ["client_id", "project_id"],
  data: () => ({
    keywords: "",
    outputPoolFormDialog: false,
    formOutputPool: {},
    reloadGrid: 0,
    versionDialog: false,
    currentPool: {},
    showStartDatePicker: false,
    showEndDatePicker: false,
    outputFilterStartDate: null,
    outputFilterEndDate: null,
    inValidOutputFilterDates: false
  }),
  computed: {
    theStartPublishDateText() {
      return this.outputFilterStartDate
        ? this.formatDate(this.outputFilterStartDate)
        : "";
    },
    theEndPublishDateText() {
      return this.outputFilterEndDate
        ? this.formatDate(this.outputFilterEndDate)
        : "";
    },
    isSuperAdmin() {
      return this.$store.getters.userForPortalAccess.user_type === "admin";
    }
  },
  methods: {
    newOutputPoolForm: function() {
      this.formOutputPool = {};
      this.outputPoolFormDialog = true;
    },
    closeOutputPoolForm: function() {
      this.outputPoolFormDialog = false;
    },
    openVersionDialog(data) {
      this.currentPool = data;
      this.versionDialog = true;
    },
    closeVersionDialog: function() {
      this.versionDialog = false;
    },
    editOutputPool: function(pool) {
      this.formOutputPool = pool;
      this.outputPoolFormDialog = true;
    },
    reloadTheGrid: function() {
      this.reloadGrid++;
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
    },
    updateStartDate(date) {
      this.outputFilterStartDate = date;
      this.showStartDatePicker = false;
      this.validateOutputFilterDates();
    },
    updateEndDate(date) {
      this.outputFilterEndDate = date;
      this.showEndDatePicker = false;
      this.validateOutputFilterDates();
    },
    clearOutputEndDate() {
      this.outputFilterEndDate = null;
      this.validateOutputFilterDates();
    },
    clearOutputStartDate() {
      this.outputFilterStartDate = null;
      this.validateOutputFilterDates();
    },
    formatInputDateToTimeNum(date) {
      return moment(date, "YYYY-MM-DD").valueOf();
    },
    validateOutputFilterDates() {
      if (this.outputFilterStartDate && this.outputFilterEndDate) {
        this.inValidOutputFilterDates = this.formatInputDateToTimeNum(this.outputFilterStartDate) > this.formatInputDateToTimeNum(this.outputFilterEndDate);
      } else {
        this.inValidOutputFilterDates = false;
      }
    }
  }
};
</script>
