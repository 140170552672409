<template>
  <v-form ref="form">
    <v-card>
      <v-card-title>
        <span class="headline">Deploy Build Version Form</span>
      </v-card-title>
      <v-card-text v-if="!formLoading">
        <p>
          Use this form to copy the uploaded build from the pending directory to
          a versioned folder. If you make this the current version, any targets
          tied to this content pool will use these files. If you make this the
          testing version, then any targets tied to this pool will be used for
          testing links. Nothing will be overwritten in either case.
        </p>
        <v-container v-if="!formLoading">
          <v-row>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="formData.version"
                :label="$t('version')"
                :disabled="formLoading"
                required
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-radio-group v-model="formData.is_current" row>
                <template v-slot:label>
                  <div>Current version?</div>
                </template>
                <v-radio label="Yes" value="y"></v-radio>
                <v-radio label="No" value="n"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6">
              <v-radio-group v-model="formData.is_testing" row>
                <template v-slot:label>
                  <div>Test version?</div>
                </template>
                <v-radio label="Yes" value="y"></v-radio>
                <v-radio label="No" value="n"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" sm="12">
              <v-textarea
                name="notes"
                label="Version Notes"
                v-model="formData.notes"
                hint="Enter notes here..."
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-text v-else>
        <v-progress-linear v-if="formLoading" indeterminate />
        Deploying files...
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red darken-1"
          text
          @click="closeDialog"
          :disabled="formLoading"
          >{{ $t("close") }}</v-btn
        >
        <v-btn
          v-if="formData.id"
          color="primary darken-1"
          text
          :disabled="formLoading"
          @click="updateVersion"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          v-if="!formData.id"
          color="primary darken-1"
          text
          :disabled="formLoading"
          @click="createVersion"
          >{{ $t("create") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
export default {
  name: "DeployFilesDialog",
  props: {
    pool: {
      type: Object,
      required: true
    },
    build_type: {
      type: String,
      required: true
    }
  },
  data: () => ({
    formLoading: false,
    defaultForm: {
      id: "",
      version: "",
      is_current: "n",
      is_testing: "n",
      build_type: "",
      notes: ""
    },
    formData: {}
  }),
  methods: {
    closeDialog: function() {
      this.$emit("deploy-files-closed");
    },
    createVersion: function() {
      this.formData.build_type = this.build_type;
      this.mutation = require("@/graphql/output-pool-versions/createOutputPoolVersion.gql");
      this.saveForm();
    },
    updateVersion: function() {
      this.formData.build_type = this.build_type;
      this.mutation = require("@/graphql/output-pool-versions/updateOutputPoolVersion.gql");
      this.saveForm();
    },
    saveForm() {
      this.formLoading = true;
      this.$apollo
        .mutate({
          // Query
          mutation: this.mutation,
          // Parameters
          variables: {
            id: this.formData.id,
            version: this.formData.version,
            is_testing: this.formData.is_testing,
            is_current: this.formData.is_current,
            build_type: this.formData.build_type,
            output_pool: this.pool.id,
            notes: this.formData.notes
          }
        })
        .then(data => {
          // report success
          this.$emit("deploy-files-saved");
          this.$emit("deploy-files-closed", data);
          this.$store.dispatch("responseMessage", {
            type: "success",
            title: "Success",
            text: "Version saved"
          });
          this.formLoading = false;
          this.formData = { ...this.defaultForm };
        })
        .catch(error => {
          // Error
          console.error(error);
          this.$emit("deploy-files-closed", error);
          this.$store.dispatch("responseMessage", {
            type: "error",
            title: "Error",
            text: error
          });
          this.formLoading = false;
        });
    }
  },
  created() {
    this.formData = { ...this.defaultForm };
  },
  computed: {}
};
</script>
