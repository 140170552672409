<template>
  <v-form ref="form">
    <v-card>
      <v-card-title>
        <span class="headline">Output Target Item Form</span>
      </v-card-title>
      <v-card-text>
        <v-progress-linear v-if="formLoading" indeterminate />
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="formData.name"
                :label="$t('name')"
                :disabled="formLoading"
                required
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="formData.version"
                :label="$t('version')"
                :disabled="formLoading"
                required
              />
            </v-col>

            <v-col cols="6">
              <ApolloQuery
                :query="require('@/graphql/client/clientsDropdown.gql')"
              >
                <template v-slot="{ result: { loading, error, data } }">
                  <v-autocomplete v-if="loading" label="Loading..." />
                  <v-autocomplete
                    v-else-if="data"
                    :items="data.allClients"
                    :disabled="formLoading"
                    :loading="loading"
                    item-text="name"
                    item-value="id"
                    v-model="client_id"
                    :label="$t('client')"
                    required
                  />
                </template>
              </ApolloQuery>
            </v-col>

            <v-col cols="6">
              <ApolloQuery
                :query="
                  require('@/graphql/output-pool/outputPoolsDropdown.gql')
                "
                :variables="{ client_id: formData.client.id }"
              >
                <template v-slot="{ result: { loading, error, data } }">
                  <v-autocomplete v-if="loading" label="Loading..." />
                  <v-autocomplete
                    v-else-if="data"
                    :items="data.allOutputPools"
                    :disabled="formLoading"
                    :loading="loading"
                    item-text="name"
                    item-value="id"
                    v-model="output_pool_id"
                    :label="$t('output_pool')"
                    required
                  />
                </template>
              </ApolloQuery>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="formData.build_type"
                :items="build_types"
                label="Build Type"
                item-text="value"
                item-value="id"
                required
                :disabled="formLoading"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="formData.target_url"
                :label="$t('target_url')"
                :disabled="formLoading"
                required
              />
            </v-col>
            <v-col cols="6">
              <v-file-input
                v-model="formData.file_upload"
                accept="image/*"
                label="Thumbnail Image"
                :disabled="formLoading"
              />
            </v-col>

            <v-col cols="6" v-if="target.img != ''">
              <p>Current Image</p>
              <v-img
                class="white--text align-end"
                max-width="100"
                contain
                :src="imgSource"
              >
              </v-img>
            </v-col>
            <v-col v-if="isSuperAdmin" cols="12" style="margin-bottom:-25px;">
              <v-checkbox
                v-model="formData.is_favorite"
                label="Add to dashboard"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" style="margin-top:-25px;">
              <v-checkbox
                v-model="formData.require_client_admin"
                label="This target requires client administrator privileges"
                value="y"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red darken-1"
          text
          @click="$emit('target-form-closed')"
          :disabled="formLoading"
          >{{ $t("close") }}</v-btn
        >
        <v-btn
          v-if="formData.id"
          color="primary darken-1"
          text
          :disabled="formLoading"
          @click="updateTarget()"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          v-if="!formData.id"
          color="primary darken-1"
          text
          :disabled="formLoading"
          @click="createTarget()"
          >{{ $t("create") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
export default {
  name: "OutputTargetForm",
  props: {
    target: {
      type: Object,
      default: () => ({
        id: "",
        name: "",
        version: "",
        img: "",
        sort: "",
        target_url: "",
        is_favorite: false,
        client: {},
        project: {},
        file_upload: null,
        output_pool: {}
      })
    }
  },
  data: () => ({
    isValid: true,
    mutation: "",
    formLoading: false,
    build_types: [
      { id: "pdf", value: "PDF" },
      { id: "web", value: "Web" }
    ],
    defaultForm: {
      id: "",
      name: "",
      img: "",
      sort: "",
      version: "",
      target_url: "",
      build_type: "",
      client: {},
      project: {},
      output_pool: {},
      require_client_admin: "n",
      is_favorite: false,
      file_upload: null
    },
    formData: {}
  }),
  created() {
    this.formData = { ...this.defaultForm, ...this.target };
  },
  computed: {
    isSuperAdmin() {
      return this.$store.getters.userForPortalAccess.user_type === "admin";
    },
    imgSource: function() {
      return (
        process.env.VUE_APP_API_ENDPOINT +
        "assets/target/img/" +
        this.target.img
      );
    },
    client_id: {
      // this feels wrong, but it works...
      // getter
      get: function() {
        if (typeof this.target.client !== "undefined") {
          if (typeof this.target.client.id !== "undefined") {
            return this.target.client.id;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      // setter
      set: function(newValue) {
        this.formData.client = { id: newValue };
      }
    },
    project_id: {
      // this feels wrong, but it works...
      // getter
      get: function() {
        if (typeof this.target.project !== "undefined") {
          if (typeof this.target.project.id !== "undefined") {
            return this.target.project.id;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      // setter
      set: function(newValue) {
        this.formData.project = { id: newValue };
      }
    },
    output_pool_id: {
      // this feels wrong, but it works...
      // getter
      get: function() {
        if (typeof this.target.output_pool !== "undefined") {
          if (typeof this.target.output_pool.id !== "undefined") {
            return this.target.output_pool.id;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      // setter
      set: function(newValue) {
        this.formData.output_pool = { id: newValue };
      }
    }
  },
  methods: {
    validate() {
      // if (this.$refs.form.validate()) {
      //   this.isValid = true;
      //   this.saveForm();
      // }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    updateTarget() {
      this.mutation = require("@/graphql/output-target/updateOutputTarget.gql");
      this.saveForm();
    },
    createTarget() {
      this.mutation = require("@/graphql/output-target/createOutputTarget.gql");
      this.saveForm();
    },
    saveForm() {
      this.formLoading = true;
      if (this.formData.require_client_admin !== "y") {
        this.formData.require_client_admin = "n";
      }
      this.$apollo
        .mutate({
          // Query
          mutation: this.mutation,
          // Parameters

          variables: {
            id: this.formData.id,
            name: this.formData.name,
            version: this.formData.version,
            target_url: this.formData.target_url,
            client: this.formData.client.id,
            output_pool: this.formData.output_pool.id,
            file_upload: this.formData.file_upload,
            build_type: this.formData.build_type,
            require_client_admin: this.formData.require_client_admin,
            is_favorite: this.formData.is_favorite,
            sort: 0
          }
        })
        .then(data => {
          // report success
          this.$emit("target-form-saved");
          this.$emit("target-form-closed", data);
          this.$store.dispatch("responseMessage", {
            type: "Success",
            title: "Success",
            text: "Target Saved"
          });
          this.formLoading = false;
        })
        .catch(error => {
          // Error
          this.formLoading = false;
          this.$store.dispatch("responseMessage", {
            type: "error",
            title: "Error",
            text: error
          });
        });
    }
  },
  watch: {
    target: {
      handler: function() {
        this.formData = { ...this.defaultForm, ...this.target };
      },
      deep: true
    }
  }
};
</script>
